import {ethers} from 'ethers'
import store from '../store'
import axios from 'axios'
import artAbi from './abi'
import tokenAbi from './token'
import config from './config'

let providers;

// const EtherNetwork = 'homestead';
// const EtherNetwork = 1337;
const EtherNetwork = 'goerli';

const Ether = { };

const artABIStr = JSON.stringify(artAbi.abi)
const tokenABIStr = JSON.stringify(tokenAbi.abi)

let provider = new ethers.providers.InfuraProvider(EtherNetwork, config.infura_key)
let signer

const artAddress = '0x10b9cC1A49386b2e5161fc22BE0973c0E5d6a17A'

let artContract = new ethers.Contract(artAddress, artABIStr, provider)

async function reloadContract(signer) {
  artContract = new ethers.Contract(artAddress, artABIStr, signer)
}



Ether.Wallet = function () {
  this.init = async () => {
    try {
      window.ethereum
    } catch (error) {
      console.error('No Ethereum')
    }
    const active = localStorage.getItem('address')
    if (window.ethereum && active !== '') {
      provider = new ethers.providers.Web3Provider(window.ethereum, EtherNetwork)
      await this.connectCallback()
    }
    // if (localStorage.getItem('sign')) {
    //   store.commit('SetSign', localStorage.getItem('sign'))
    // }
  }
  this.connectCallback = async () => {
    signer = provider.getSigner();
    await reloadContract(signer)
    const oldAddress = localStorage.getItem('address')
    try {
      const a = await signer.getAddress()

      if (oldAddress !== a) {
        // change wallet
        localStorage.setItem('address', a)
        store.commit('SetAddress', a)
        const sign = await signer.signMessage(a)
        localStorage.setItem('sign', sign)
        await reloadContract(signer)
        // await this.login(a, sign)
        return
      }

      store.commit('SetAddress', a)
      // let sign = localStorage.getItem('sign')
      // const token = localStorage.getItem('token')
      // if (!sign) {
      //   sign = await signer.signMessage(a)
      //   localStorage.setItem('sign', sign)
      //   await this.login(a, sign)
      // } else if (!token) {
      //   await this.login(a, sign)
      // }
    } catch (e) {
      console.log('not connect')
    }
  }
  this.getConnect = async (wallet) => {
    try {
      window.ethereum
    } catch (error) {
      console.error('No Ethereum')
    }
    let windowProvider
    if (wallet === 'metamask') {
      try {
        windowProvider = window.ethereum.providers.find((x) => x.isMetaMask)
      } catch(e) {
        windowProvider = window.ethereum
      }
    } else {
      try {
        windowProvider = window.ethereum.providers.find((x) => x.isCoinbaseWallet)
      } catch(e) {
        windowProvider = window.ethereum
      }
    }
    console.log(windowProvider)
    if (windowProvider) {
      try {
        await windowProvider.request({
          method: 'eth_requestAccounts',
        })
      } catch (error) {
        console.error('User denied account')
      }
      provider = new ethers.providers.Web3Provider(windowProvider, EtherNetwork)
      await this.connectCallback()
    } else {
      console.log('no plugin installed')
      return 'No Ethereum'
    }
  }

  this.connectByScan = async () => {
    providers = new window.WalletConnectProvider.default({
      infuraId: config.infura_key
    })
    await providers.disconnect()
    try {
      const accounts = await providers.enable()
      provider = new ethers.providers.Web3Provider(providers, EtherNetwork)
      await this.connectCallback()
    } catch (err) {
      console.log(err)
    }
  }

  this.disconnect = async () => {
    localStorage.setItem('address', '')
    store.commit('SetAddress', '')
    await providers.disconnect()
  }

  this.getTokenPricesById = async (index) => {
    console.log(index)
    return await artContract.getTokenPricesById(index)
  }

  this.getArts = async () => {
    return await artContract.getArts()
  }

  this.createArt = async () => {
    const tx = await artContract.createArt(
      100,
      ethers.utils.parseEther('0.0015'),
      ethers.utils.parseEther('300000000')
    )
    console.log(tx)
  }

  this.mint = async (amount, id, value) => {
    const tx = await artContract.mint(amount, id, {
      value: ethers.utils.parseEther(`${parseFloat((value).toFixed(5))}`)
    })
    await tx.wait()
  }

  this.tokenMint = async (amount, id, tokenContract) => {
    const tx = await artContract.dogcMint(amount, id, tokenContract)
    await tx.wait()
  }

  this.checkApprove = async (tokenAddress, amount) => {
    console.log(tokenAddress, amount)
    const tokenContract = new ethers.Contract(tokenAddress, tokenABIStr, signer)
    const userAddress = store.state.address
    const allowance = await tokenContract.allowance(userAddress, artAddress)
    console.log(allowance)
    console.log(amount)
    if (Number(allowance) < Number(amount)) {
      const tx = await tokenContract.approve(artAddress, amount)
      await tx.wait()
    }
  }

  this.login = async (name, sign) => {
    axios
      .post('https://api.memeparty.io/api/auth/local', {
        identifier: name,
        password: sign,
      })
      .then(response => {
        const data = response.data
        store.commit('SetToken', data.jwt)
        localStorage.setItem('token', data.jwt)
      })
      .catch(error => {
        // Handle error.
        console.log(error)
        if (error.response.status === 400) {
          this.registrate(name, sign)
        }
      });
  }

  this.registrate = async (name, sign) => {
    axios
      .post('https://api.memeparty.io/api/auth/local/register', {
        username: name,
        email: `${name}@memeparty.app`,
        password: sign,
      })
      .then(res => {
        const data = res.data
        store.commit('SetToken', data.jwt)
        localStorage.setItem('token', data.jwt)
      })
      .catch(error => {
        // Handle error.
        console.log('An error occurred:', error.response);
      });
  }

}

export default Ether
