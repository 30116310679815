<template>
  <div id="app">
    <AppHeader v-if="$route.name === 'home'" />
    <router-view/>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style lang="less">
svg{
  vertical-align: text-top;
}
@font-face {
  font-family: "Chango-Regular";
  src: url("./assets/fonts/Chango-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("./assets/fonts/Montserrat-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Black";
  src: url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("./assets/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("./assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("./assets/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}



/* 滚动条整体 */
.custom-scroll::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}
/* 两个滚动条交接处 -- x轴和y轴 */
.custom-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* 滚动条滑块 */
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0);
  background: #ededed;
}

/* 滚动条轨道 */
.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  background: transparent;
}

/* 滚动条两端按钮 */
.custom-scroll::-webkit-scrollbar-button {
}

.tooltip {
  margin: 5px;
  z-index: 100;
  height: 25px;
  background: rgba(0,0,0,0.76);
  border-radius: 4px 4px 4px 4px;
  font-size: 12px;
  padding: 0 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 25px;
}

</style>
